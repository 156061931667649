import { cn } from '~/utils/misc.tsx'
import { formatPrice } from '~/utils/strings.ts'

export const Price = ({
  amount,
  currency,
  className,
}: {
  amount: number
  currency: String
  className?: string
}) => {
  return (
    <span className={cn('flex gap-1 whitespace-normal pl-2', className)}>
      {/* Schema.org markup for price */}
      <div itemProp="price" content={amount.toString()}>
        {formatPrice(amount)}
      </div>
      <div itemProp="priceCurrency" content={currency.toString()}>
        {currency}
      </div>
    </span>
  )
}
